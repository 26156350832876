import { Inject, Injectable } from '@angular/core';

import * as signalR from '@aspnet/signalr';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { BehaviorSubject, from, Observable, Observer, Subject } from 'rxjs';
import { ISignalRConfig, SIGNAL_R_CONFIGURATION } from './signalRConfig';

// https://www.c-sharpcorner.com/article/getting-started-with-signalr-using-aspnet-co-using-angular-5/
// https://blogs.msdn.microsoft.com/atverma/2018/11/02/add-real-time-web-functionality-to-angular-application-using-asp-net-core-signalr-azure-signalr-service-and-azure-signalr-service-bindings-for-azure-functions-2-0/

export interface IHubConnection {
  status: BehaviorSubject<HubConnectionStatus>;
  on(methodName: string): Observable<any>;
  invoke(methodName: string, args: Array<any>): Observable<void>;
  close(): void;
}

export enum HubConnectionStatus {
  connecting,
  connected,
  disconnected
}

export interface IHubMethodObserver {
  methodName: string;
  observers: Array<Observer<any>>;
}

@Injectable({
  providedIn: 'root',
})
export class SignalRCoreService {
  private _config: ISignalRConfig;

  constructor(@Inject(SIGNAL_R_CONFIGURATION) configuration: ISignalRConfig) {
    this._config = configuration;
  }

  connect(hubName: string, accessTokenFactory: () => string | Promise<string>): IHubConnection {
    let subscribedMethods: Array<IHubMethodObserver> = [];

    const connectionStateSubject = new BehaviorSubject<HubConnectionStatus>(HubConnectionStatus.disconnected);

    const close = () => {
      if (hubConnection) {
        const currentHubConnection = hubConnection;
        (hubConnection as any) = undefined;
        this.stopHub(currentHubConnection, connectionStateSubject, subscribedMethods);
        subscribedMethods = [];
      }
    };

    const invoke = (methodName: string, args: Array<any>): Observable<void> => {
      return from(hubConnection.invoke(methodName, ...args));
    };

    const on = (methodName: string) => {
      let methodObservable: IHubMethodObserver = subscribedMethods.find(o => o.methodName === methodName) as IHubMethodObserver;
      const methodSubscribed = !!methodObservable;
      if (!methodObservable) {
        methodObservable = {
          methodName,
          observers: []
        };
        subscribedMethods.push(methodObservable);
      }
      return new Observable((observer: Observer<any>) => {
        methodObservable.observers.push(observer);
        if (!methodSubscribed) {
          this.on(hubConnection, methodObservable);
        }
        return () => {
          console.log(`SignalR hub method '${methodName}' unsubscribed.`);
          const i = methodObservable.observers.indexOf(observer);
          methodObservable.observers.splice(i, 1);
          if (!methodObservable.observers.length) {
            this.off(hubConnection, methodName);
          }
        };
      });
    };

    const result: IHubConnection = {
      invoke,
      on,
      status: connectionStateSubject,
      close
    };

    let hubConnection = new HubConnectionBuilder()
      .withUrl(`${this._config.signalRUrl}/${hubName}`,
        {accessTokenFactory})
      .configureLogging(signalR.LogLevel.Information)
      .build();

    hubConnection.onclose((err: any) => {
      console.log('SignalR hub connection closed.');
      if (hubConnection) {
        this.stopHub(hubConnection, connectionStateSubject, subscribedMethods);
        this.restartConnection(hubConnection, connectionStateSubject, subscribedMethods, err);
      }
    });

    this.startConnection(hubConnection, connectionStateSubject, subscribedMethods).then(() => {
      console.log('Established initial SignalR hub connection.');
    });
    return result;

  }

  private startConnection(hubConnection: HubConnection, connectedSubject: Subject<HubConnectionStatus>, subscribedMethods: Array<IHubMethodObserver>): Promise<void> {
    return hubConnection
      .start()
      .then(() => {
        console.log('SignalR Hub connection started');
        this.subscribeToServerEvents(hubConnection, subscribedMethods);
        connectedSubject.next(HubConnectionStatus.connected);
      })
      .catch(err => {
        this.restartConnection(hubConnection, connectedSubject, subscribedMethods, err);
      });
  }

  private restartConnection(hubConnection: HubConnection, connectionStateSubject: Subject<HubConnectionStatus>, subscribedMethods: Array<IHubMethodObserver>, err: Error): void {
    connectionStateSubject.next(HubConnectionStatus.connecting);
    console.log(`Error ${err}`);
    console.log('Retrying connection to SignalR Hub ...');
    setTimeout(() => {
      this.startConnection(hubConnection, connectionStateSubject, subscribedMethods).then(() => {
        console.log('Reconnecting to SignalR Hub completed.');
      });
    }, 3000);
  }

  private stopHub(hubConnection: HubConnection, connectionStateSubject: Subject<HubConnectionStatus>, subscribedMethods: Array<IHubMethodObserver>): void {
    if (subscribedMethods) {
      subscribedMethods.forEach(m => {
        this.off(hubConnection, m.methodName);
      });
    }
    if (hubConnection) {
      hubConnection.stop().then(() => {
        console.log('Hub connection stopped');
        connectionStateSubject.next(HubConnectionStatus.disconnected);
      });
    }
  }

  private off(hubConnection: HubConnection, methodName: string) {
    hubConnection.off(methodName);
    console.log(`SignalR hub method '${methodName}' handler destroyed.`);
  }

  private subscribeToServerEvents(hubConnection: HubConnection, subscribedMethods: Array<IHubMethodObserver>) {
    subscribedMethods.forEach(m => {
      hubConnection.off(m.methodName); // detach existing handler
      this.on(hubConnection, m);
    });
  }

  private on(hubConnection: HubConnection, methodObservable: IHubMethodObserver) {
    hubConnection.on(methodObservable.methodName, (...args: any[]) => {
      console.log(`SignalR hub method '${methodObservable.methodName}' called.`);
      methodObservable.observers.forEach(o => {
        o.next(args);
      });
    });
    console.log(`SignalR hub method '${methodObservable.methodName}' handler attached.`);
  }
}
